import type { AxiosResponse } from 'axios';
import useDialogStore, { type dialogPayload } from '@/stores/dialog';
import type { APIRes } from '@/interface/Global';

/** Response has Error processor - Basic */
export default <T = any>(res: AxiosResponse<APIRes<T>> | APIRes<T>, hasErrorCode: boolean = false) => {
  const { data, code, message } = 'code' in res ? res : res.data;

  if (data === undefined || code === undefined || message === undefined)
    return res.data as T;

  if (+code !== 1)
    throw !hasErrorCode ? new Error(message) : new Error(`${code}: ${message}`);

  return data;
};

/** Response hasn't Error processor */
export function hasNotErrorProcessor<T = any>(res: AxiosResponse<APIRes<T>> | APIRes<T>): APIRes<T> {
  return 'code' in res ? res : res.data;
}

/** API Error Processor */
export function errorCatchProcessor(error: any, config?: dialogPayload) {
  const dialog = useDialogStore();

  if (error.message === 'ERR_CANCELED')
    return;

  if (!config)
    dialog.showDialog({ title: '提示', text: error.message });
  else
    dialog.showDialog(config);

  console.error(error);
}
