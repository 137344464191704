import type { InternalAxiosRequestConfig } from 'axios';
import { defineStore } from 'pinia';

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  id?: string
}

export default defineStore('RequestStore', () => {
  const activeRequests = ref(new Map<string, AbortController>());

  // 新增請求
  function addRequest(uuid: string) {
    const controller = new AbortController();
    activeRequests.value.set(uuid, controller);
    return controller.signal;
  }

  // 取消單一請求
  function cancelRequest(uuid: string) {
    if (activeRequests.value.has(uuid)) {
      activeRequests.value.get(uuid)?.abort();
      activeRequests.value.delete(uuid);
    }
  }

  // 取消所有請求（頁面切換或組件卸載時）
  function cancelAllRequests() {
    activeRequests.value.forEach(controller => controller.abort());
    activeRequests.value.clear();
  }

  return {
    activeRequests,
    addRequest,
    cancelRequest,
    cancelAllRequests,
  };
});
