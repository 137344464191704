import { createApp as createBaseApp } from 'vue';
import { createPinia } from 'pinia';
import VueFeather from 'vue-feather';
import VueLazyload from 'vue-lazyload';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import iconClock from './components/Icons/Clock.vue';
import App from './App.vue';
import router from './router';
import ElementPlusCustom from './assets/js/element-plus.full.mjs';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import 'element-plus/dist/index.css';
import './assets/scss/abstracts/element-variables.scss';
import './assets/scss/main.scss';
import 'virtual:uno.css';

const store = createPinia();
export function createApp(options, rootProps) {
  const app = createBaseApp(options, rootProps);
  app
    .use(ElementPlusCustom)
    .use(router)
    .use(store)
    .use(CkeditorPlugin)
    .use(VueLazyload, { lazyComponent: true })
    .component('iconClock', iconClock)
    .component((VueFeather as any).name, VueFeather);

  return app;
}

const app = createApp(App, {});
app.mount('#app');
