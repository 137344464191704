import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import useLogin from '@/stores/login';
import useRequestStore from '@/stores/request';
import { API_GET_USER } from '@/plugins/api';
import { useRedirectStore } from '@/stores';
import useDialog from '@/stores/dialog';
import searchCacheStore from '@/stores/searchCache';

const routesAuthMap = {
  m_a: 'home',

  b_01: 'reportBrowse',
  b_02: 'reportSales',
  b_03: 'reportTarget',
  b_04: 'reportProduct',
  b_05: 'reportInsight',

  m_d: 'memberlist',

  e_01: 'tags-custom',
  e_02: 'tags-external',
  e_03: 'tags-system',

  f_01: 'targetCustomList',
  f_02: 'smartList',

  g_01: 'activity-default',
  g_02: 'template',
  g_03: 'analytics',

  h_01: 'setting-account',
  h_02: 'setting-channel',
  h_03: 'setting-send',

  m_i: 'teachingCenter',
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      firstRoute: '首頁',
      title: '首頁',
      useMainframe: true,
      menuAuth: ['m_a'],
    },
  },
  {
    path: '/report_browse',
    name: 'reportBrowse',
    component: () => import('@/views/ReportBrowse.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '瀏覽行為分析',
      useMainframe: true,
      menuAuth: ['m_b', 'b_01'],
    },
  },
  {
    path: '/report_sales',
    name: 'reportSales',
    component: () => import('@/views/ReportSales.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '銷售指標',
      useMainframe: true,
      menuAuth: ['m_b', 'b_02'],
    },
  },
  {
    path: '/report_target',
    name: 'reportTarget',
    component: () => import('@/views/ReportTarget.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '受眾比較分析',
      useMainframe: true,
      menuAuth: ['m_b', 'b_03'],
    },
  },
  {
    path: '/report_product',
    name: 'reportProduct',
    component: () => import('@/views/ReportProduct.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '商品指標',
      useMainframe: true,
      menuAuth: ['m_b', 'b_04'],
    },
  },
  {
    path: '/report_insight',
    name: 'reportInsight',
    component: () => import('@/views/ReportInsightList.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '洞察報告',
      useMainframe: true,
      menuAuth: ['m_b', 'b_05'],
    },
  },
  {
    path: '/report_insight/add',
    name: 'reportInsightAdd',
    component: () => import('@/views/ReportInsightDetail.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '新增報告',
      useMainframe: true,
      menuAuth: ['m_b', 'b_05'],
    },
    props: () => ({
      pageType: 'add',
    }),
  },
  {
    path: '/report_insight/view/:id',
    name: 'reportInsightView',
    component: () => import('@/views/ReportInsightDetail.vue'),
    meta: {
      firstRoute: '報表戰情室',
      title: '檢視報告',
      useMainframe: true,
      menuAuth: ['m_b', 'b_05'],
    },
    props: () => ({
      pageType: 'view',
    }),
  },
  {
    path: '/memberlist',
    name: 'memberlist',
    component: () => import('@/views/MemberList.vue'),
    meta: {
      firstRoute: '會員管理',
      title: '會員管理',
      useMainframe: true,
      menuAuth: ['m_d'],
    },
  },
  {
    path: '/memberlist/:id',
    name: 'memberlist-view',
    component: () => import('@/views/MemberList.vue'),
    meta: {
      firstRoute: '會員管理',
      title: '會員管理',
      useMainframe: true,
      menuAuth: ['m_d'],
    },
  },
  {
    path: '/member/:id',
    name: 'memberinfo',
    component: () => import('@/views/MemberInfo.vue'),
    meta: {
      firstRoute: '會員資訊',
      title: '會員資訊',
      useMainframe: true,
      menuAuth: ['m_d'],
    },
  },
  {
    path: '/tags/custom',
    name: 'tags-custom',
    component: () => import('@/views/TagsList.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/external',
    name: 'tags-external',
    component: () => import('@/views/TagsList.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '外部匯入',
      useMainframe: true,
      menuAuth: ['m_e', 'e_02'],
    },
  },
  {
    path: '/tags/system',
    name: 'tags-system',
    component: () => import('@/views/TagsList.vue'),
    meta: {
      firstRoute: '標籤',
      title: '智慧標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_03'],
    },
  },
  {
    path: '/tags/category',
    name: 'tags-category',
    component: () => import('@/views/TagsCategoryDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/category/:id',
    name: 'tags-category-edit',
    component: () => import('@/views/TagsCategoryDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/add',
    name: 'tags-add',
    component: () => import('@/views/TagsDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/script',
    name: 'tags-add-script',
    component: () => import('@/views/TagsDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/edit/:id',
    name: 'tags-edit',
    component: () => import('@/views/TagsDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/view/:id',
    name: 'tags-view',
    component: () => import('@/views/TagsDetail.vue'),
    props: route => ({ source: route.query.source }),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/tags/upload',
    name: 'tags-upload',
    component: () => import('@/views/TagsDetail.vue'),
    meta: {
      firstRoute: '標籤管理',
      title: '自訂標籤',
      useMainframe: true,
      menuAuth: ['m_e', 'e_01'],
    },
  },
  {
    path: '/target_custom_list',
    name: 'targetCustomList',
    component: () => import('@/views/TargetCustomListView.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '自訂受眾',
      useMainframe: true,
      menuAuth: ['m_f', 'f_01'],
    },
  },
  {
    path: '/target_custom/add',
    name: 'targetCustomAdd',
    component: () => import('@/views/TargetCustom.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '自訂受眾',
      useMainframe: true,
      menuAuth: ['m_f', 'f_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'targetCustomList',
      },
    },
  },
  {
    path: '/target_custom/edit/:id',
    name: 'targetCustomEdit',
    component: () => import('@/views/TargetCustom.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '自訂受眾',
      useMainframe: true,
      menuAuth: ['m_f', 'f_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'targetCustomList',
      },
    },
  },
  {
    path: '/target_custom/view/:id',
    name: 'targetCustomView',
    component: () => import('@/views/TargetCustom.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '自訂受眾',
      useMainframe: true,
      menuAuth: ['m_f', 'f_01'],
    },
  },
  {
    path: '/target/smart',
    name: 'smartList',
    component: () => import('@/views/TargetSmartList.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '智慧名單',
      useMainframe: true,
      menuAuth: ['m_f', 'f_02'],
    },
  },
  {
    path: '/target_dashboard/:type/:id',
    name: 'targetDashboard',
    component: () => import('@/views/TargetDashboard.vue'),
    meta: {
      firstRoute: '分群受眾',
      title: '受眾描述',
      useMainframe: true,
      menuAuth: ['m_f'],
    },
  },
  {
    path: '/activity/custom',
    name: 'activity-custom',
    component: () => import('@/views/ActivityList.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '行銷自動化',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/activity/custom/add',
    name: 'activityAdd',
    component: () => import('@/views/ActivityDetailView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '新增活動',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'activity',
      },
    },
  },
  {
    path: '/activity/custom/edit/:id',
    name: 'activityEdit',
    component: () => import('@/views/ActivityDetailView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '編輯活動',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/activity/custom/view/:id',
    name: 'activityView',
    component: () => import('@/views/ActivityDetailView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '檢視活動',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/activity/default',
    name: 'activity-default',
    component: () => import('@/views/ActivityList.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '行銷自動化',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/activity/default/add',
    name: 'activity-default-detail-add',
    component: () => import('@/views/ScriptDefaultDetail.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '新增預設劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/activity/default/:id',
    name: 'activity-default-detail',
    component: () => import('@/views/ScriptDefaultDetail.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '編輯預設劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/script/add',
    name: 'scriptAdd',
    component: () => import('@/views/ScriptView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '新增劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'activity',
      },
    },
  },
  {
    path: '/script/addto/:id',
    name: 'scriptAddTo',
    component: () => import('@/views/ScriptView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '新增劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/script/edit/:id',
    name: 'scriptEdit',
    component: () => import('@/views/ScriptView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '編輯劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/script/view/:id',
    name: 'scriptView',
    component: () => import('@/views/ScriptView.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '檢視劇本',
      useMainframe: true,
      menuAuth: ['m_g', 'g_01'],
    },
  },
  {
    path: '/template',
    name: 'template',
    component: () => import('@/views/TemplateList.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '模板管理',
      useMainframe: true,
      menuAuth: ['m_g', 'g_02'],
    },
  },
  {
    path: '/template/add',
    name: 'template-add',
    component: () => import('@/views/TemplateBuilder.vue'),
    props: route => ({ step: route.query.step ? +route.query.step : 1 }),
    meta: {
      firstRoute: '行銷管理',
      title: '新增模板',
      useMainframe: true,
      menuAuth: ['m_g', 'g_02'],
    },
  },
  {
    path: '/template/edit/:id',
    name: 'template-edit',
    component: () => import('@/views/TemplateBuilder.vue'),
    props: route => ({ step: route.query.step ? +route.query.step : 1 }),
    meta: {
      firstRoute: '行銷管理',
      title: '編輯模板',
      useMainframe: true,
      menuAuth: ['m_g', 'g_02'],
    },
  },
  {
    path: '/template/view/:id',
    name: 'template-view',
    component: () => import('@/views/TemplateBuilder.vue'),
    props: route => ({ step: route.query.step ? +route.query.step : 1 }),
    meta: {
      firstRoute: '行銷管理',
      title: '檢視模板',
      useMainframe: true,
      menuAuth: ['m_g', 'g_02'],
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/AnalyticsListV2.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '成效分析',
      useMainframe: true,
      menuAuth: ['m_g', 'g_03'],
    },
  },
  {
    path: '/analytics/view/statistic',
    name: 'analytics-statistic',
    component: () => import('@/views/AnalyticsStatisticV2.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '成效分析',
      useMainframe: true,
      menuAuth: ['m_g', 'g_03'],
    },
    props: route => ({
      pageType: 'statistic', // statistic(發送統計)/list(發送名單)
      query: route.query,
    }),
  },
  {
    path: '/analytics/view/list',
    name: 'analytics-list',
    component: () => import('@/views/AnalyticsStatisticV2.vue'),
    meta: {
      firstRoute: '行銷管理',
      title: '成效分析',
      useMainframe: true,
      menuAuth: ['m_g', 'g_03'],
    },
    props: route => ({
      pageType: 'list', // statistic(發送統計)/list(發送名單)
      query: route.query,
    }),
  },
  {
    path: '/setting/account',
    name: 'setting-account',
    component: () => import('@/views/AccountList.vue'),
    meta: {
      firstRoute: '設定',
      title: '帳號管理',
      useMainframe: true,
      menuAuth: ['m_h', 'h_01'],
    },
  },
  {
    path: '/setting/account/add',
    name: 'setting-account-add',
    component: () => import('@/views/AccountBuilder.vue'),
    meta: {
      firstRoute: '設定',
      title: '新增帳號',
      useMainframe: true,
      menuAuth: ['m_h', 'h_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'setting-account',
      },
    },
  },
  {
    path: '/setting/account/edit/:id',
    name: 'setting-account-edit',
    component: () => import('@/views/AccountBuilder.vue'),
    meta: {
      firstRoute: '設定',
      title: '變更角色',
      useMainframe: true,
      menuAuth: ['m_h', 'h_01'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'setting-account',
      },
    },
  },
  {
    path: '/setting/channel/:channel',
    name: 'setting-channel',
    component: () => import('@/views/MarketingChannel.vue'),
    meta: {
      firstRoute: '設定',
      title: '行銷渠道管理',
      useMainframe: true,
      menuAuth: ['m_h', 'h_02'],
    },
  },
  {
    path: '/setting/channel/:channel/add',
    name: 'setting-channel-add',
    component: () => import('@/views/MarketingChannel.vue'),
    meta: {
      firstRoute: '設定',
      title: '行銷渠道管理',
      useMainframe: true,
      menuAuth: ['m_h', 'h_02'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'setting-channel',
      },
    },
  },
  {
    path: '/setting/channel/:channel/edit/:id',
    name: 'setting-channel-edit',
    component: () => import('@/views/MarketingChannel.vue'),
    meta: {
      firstRoute: '設定',
      title: '行銷渠道管理',
      useMainframe: true,
      menuAuth: ['m_h', 'h_02'],
      auth: {
        allow: ['Admin', 'Manager', 'Operator'],
        redirect: 'setting-channel',
      },
    },
  },
  {
    path: '/setting/send',
    name: 'setting-send',
    component: () => import('@/views/SendSetting.vue'),
    meta: {
      firstRoute: '設定',
      title: '發送設定',
      useMainframe: true,
      menuAuth: ['m_h', 'h_03'],
    },
  },
  {
    path: '/teaching_center',
    name: 'teachingCenter',
    component: () => import('@/views/TeachingCenter.vue'),
    meta: {
      firstRoute: '教學中心',
      title: '教學中心',
      useMainframe: true,
      menuAuth: ['m_i'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    props: {
      type: 'login',
    },
    meta: {
      title: '登入',
      useMainframe: false,
      unAuth: true,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/LoginView.vue'),
    props: {
      type: 'forgot',
    },
    meta: {
      title: '忘記密碼',
      useMainframe: false,
      unAuth: true,
    },
  },
  {
    path: '/verify/reset_password/redirect',
    name: 'verify_reset',
    component: () => import('@/views/VerifyResetToken.vue'),
    props: {
      type: 'verify_reset',
    },
    meta: {
      title: '身份驗證',
      useMainframe: false,
      unAuth: true,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/LoginView.vue'),
    props: {
      type: 'reset',
    },
    meta: {
      title: '重設密碼',
      useMainframe: false,
      unAuth: true,
    },
  },
  {
    path: '/download/:type',
    name: 'download',
    component: () => import('@/views/DownloadFile.vue'),
    meta: {
      firstRoute: '',
      title: '檔案下載',
      useMainframe: false,
    },
    props: (route: any) => ({
      type: route.params.type,
      query: route.query,
    }),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/Service.vue'),
    meta: {
      firstRoute: '',
      title: '服務條款',
      useMainframe: false,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      firstRoute: '查無此頁',
      title: '查無此頁',
      useMainframe: false,
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/error',
    meta: {
      firstRoute: '查無此頁',
      title: '查無此頁',
      useMainframe: false,
    },
  },
];

const router = createRouter({
  history: import.meta.env.MODE === 'design' ? createWebHashHistory(import.meta.env.BASE_URL) : createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return Object.assign({ left: 0, top: 0 }, { x: 0, y: 0 });
  },
});

router.beforeResolve((to) => {
  const loginStore = useLogin();
  const role = loginStore.loginInfo.role;
  const { auth }: any = to.meta;

  if (auth && auth.allow) {
    if (!auth.allow.includes(role))
      router.replace({ name: auth.redirect });
  }
  nextTick().then(() => {
    const title = to?.meta?.title ? `${String(String(to?.meta?.title))} - Eagleeye CDP` : 'Eagleeye CDP';
    document.title = title;
  });
});

router.beforeEach(async (to: any, from, next) => {
  function goNext() {
    const requestStore = useRequestStore();
    requestStore.cancelAllRequests();
    next();
  }

  if (['gomaintenance', 'error'].includes(to.name)) {
    goNext();
    return;
  }

  const login = useLogin();
  const redirect = useRedirectStore();
  const searchCache = searchCacheStore();
  const dialog = useDialog();

  try {
    const res = await API_GET_USER();
    const { status, data } = res;

    if (status !== 200) {
      throw new Error('error');
    }

    login.setLogindData({
      user_id: data.user_id,
      name: data.username,
      email: data.email,
      role: data.role_name,
      corp: data.corp,
      env: data.env,
      perms: data.perms,
      apivar: data.apivar,
    });
    login.connectLogOutWebSocket();

    await login.getEtl();
    await login.getCorpDynamicField();

    // 檢查導向的route是否有權限
    const checkMenuAuth = (menuAuth) => {
      const { enable, display } = to.meta.menuAuth.length === 1 ? menuAuth[to.meta.menuAuth[0]] : menuAuth[to.meta.menuAuth[0]].menus[to.meta.menuAuth[1]];
      return menuAuth ? enable && display : true;
    };
    const routeHasMenuAuth = (to.meta.menuAuth && login.corpDynamicField !== null) ? checkMenuAuth(login.corpDynamicField.menus) : true;

    if (!routeHasMenuAuth) {
      if (to.name === 'home') {
        // 如果總覽隱藏 導去第一個不隱藏的頁面([外部數據洞察]除外)
        const corpDynamicFieldMenus = toRaw(login.corpDynamicField ? login.corpDynamicField.menus : {});
        const redirectRouteName = Object.entries(corpDynamicFieldMenus).reduce((acc, item) => {
          if (acc.length || item[0] === 'm_c')
            return acc;

          const { enable, display, menus } = item[1] as any;
          if (!acc && isEmpty(menus) && enable && display)
            return item[0];

          const findKey = Object.entries(menus).reduce((subAcc, subItem) => {
            if (subAcc.length)
              return subAcc;

            const { enable: subEnable, display: subDisplay } = subItem[1] as any;
            if (!subAcc && subEnable && subDisplay)
              return subItem[0];

            return subAcc;
          }, '');

          return findKey || acc;
        }, '');

        if (redirectRouteName)
          router.replace({ name: routesAuthMap[redirectRouteName] });
        else
          router.replace({ name: 'error' });
      }
      else {
        router.replace({ name: 'error' });
      }
    }
    else if (['login', 'forgot'].includes(to.name)) {
      router.replace({ name: 'home' });
    }
    goNext();
  }
  catch {
    const exclude = ['login', 'forgot', 'verify_reset', 'reset'];
    if (!exclude.includes(to.name)) {
      await new Promise((resolve) => {
        dialog.showDialog({
          title: '提示',
          text: '尚未登入或登入逾期',
          persistent: true,
          func: async () => {
            login.setLogout();
            searchCache.clean();
            redirect.updateIsRedirect(true);
            await router.push({ name: 'login' });
            redirect.updateIsRedirect(false);
            dialog.closeDialog();
            dialog.setDialogFreeze(false);
            resolve(true);
          },
          btnText: '返回登入',
          onlyConfirm: true,
        });
      });
    }

    goNext();

    if (exclude.includes(to.name)) {
      setTimeout(() => {
        searchCache.clean();
        login.resetInfo();
      }, 100);
    }
  }
});

export { routes, routesAuthMap };
export default router;
