import axios from 'axios';
import { CORP_TOKEN, LOGIN_TOKEN } from './cookies';
import router from '@/router';
import useDialogStore from '@/stores/dialog';
import { useLoadingStore, useRedirectStore } from '@/stores/index';
import useRequestStore, { type CustomAxiosRequestConfig } from '@/stores/request';
import searchCacheStore from '@/stores/searchCache';
import loginStore from '@/stores/login';
import { API_GET_USER } from '@/plugins/api';

export function getApiPath() {
  return window.location.origin.match('localhost')
    ? import.meta.env.VITE_API_PATH
    : window.location.origin;
}

export function getApiMgmpPath() {
  return window.location.origin.match('localhost')
    ? import.meta.env.VITE_API_MGMP_PATH
    : window.location.origin;
}

export const API_PATH = getApiPath();
export const API_MGMP_PATH = getApiMgmpPath();

interface RouterChangeDialogSetting {
  text: string
  btnText?: string
  func?: () => void
}
export function errorDialog(value: string | RouterChangeDialogSetting) {
  const dialogStore = useDialogStore();
  const searchCache = searchCacheStore();
  const redirectStore = useRedirectStore();
  const login = loginStore();

  let text = '';
  let btnText = '返回登入';
  let func: (() => void) | null = null;

  if (typeof value === 'string') {
    text = value;
  }
  else {
    text = value.text;
    btnText = value.btnText || '返回登入';
    func = value.func || null;
  }

  dialogStore.showDialog({
    title: '提示',
    text,
    persistent: true,
    func: func || (async () => {
      login.setLogout();
      searchCache.clean();
      redirectStore.updateIsRedirect(true);
      await router.push({ name: 'login' });
      redirectStore.updateIsRedirect(false);
      dialogStore.closeDialog();
      dialogStore.setDialogFreeze(false);
    }),
    btnText,
    onlyConfirm: true,
  });
}

type APIToken = 'CDP' | 'MGMP';
/**
 * API請求器
 * @param type - "CDP" | "MGMP"
 * @param setting - axios setting
 */

export function axiosReq(type: APIToken = 'CDP', setting?: object) {
  const baseReq = axios.create();
  /**
   * 第一個參數為"請求前的處理"
   * 第二個參數為"請求的錯誤處理", 包括設定錯誤及網路相關的錯誤
   */
  baseReq.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    const requestStore = useRequestStore();

    let token: string, baseURL: string;
    switch (type) {
      case 'CDP':
        baseURL = API_PATH;
        token = LOGIN_TOKEN.get() || '';
        break;
      case 'MGMP':
        baseURL = API_MGMP_PATH;
        token = CORP_TOKEN.get() || '';
    }
    const formDataReq = config.data instanceof FormData;

    config.headers['Content-Type'] = 'multipart/form-data';
    config.baseURL = baseURL;
    config.headers.Accept = 'application/json, text/plain, */*';
    config = { ...config, ...setting };
    config.headers['Content-Type'] = !formDataReq ? 'application/json' : 'multipart/form-data';
    config.headers.Authorization = `Bearer ${token}`;

    const id = uuid();
    config = { ...config, ...setting, id };

    const signal = requestStore.addRequest(`${config.url!}:${id}`);
    config.signal = signal;

    return config;
  });

  /**
   * 第一個參數為"回應200時的處理"
   * 第二個參數為"回應非200時的處理，即錯誤處理"
   */
  baseReq.interceptors.response.use((res) => {
    if (res.data && typeof (res.data) === 'string' && res.data.includes('系統維護中'))
      window.location.replace(`${window.location.origin}/gomaintenance.html`);

    const requestStore = useRequestStore();
    const { url, id } = res.config as CustomAxiosRequestConfig;
    requestStore.cancelRequest(`${url!}:${id}`);

    return res;
  }, async (error) => {
    const dialogStore = useDialogStore();
    const loading = useLoadingStore();
    const { code, message, response } = error;
    let text = '';

    if (code === 'ERR_CANCELED') {
      return Promise.reject(new Error('ERR_CANCELED'));
    }
    if (code === 'ERR_NETWORK') {
      const res = await API_GET_USER();
      if (!res)
        errorDialog('尚未登入或登入逾期');

      text = !res ? message : '程式發生問題';
    }
    else {
      const { status, statusText, data } = response;
      const errorMessage = `${data.msg || data.message || statusText || message}` || '';
      loading.updateApiLoadingStatus(false);

      if (errorMessage === 'Could not validate credentials')
        return Promise.reject(new Error(errorMessage));

      switch (status) {
        case 302:
        case 307:
          text = 'gomaintenance';
          break;
        case 403:
          text = errorMessage;
          switch (data.code) {
            case '8073':
              errorDialog({
                text: errorMessage,
                btnText: '前往忘記密碼',
                func: async () => {
                  await router.push({ name: 'forgot' });
                  dialogStore.closeDialog();
                  dialogStore.setDialogFreeze(false);
                },
              });
              dialogStore.setDialogFreeze(true);
              break;
            case '8074':
              errorDialog(errorMessage);
              dialogStore.setDialogFreeze(true);
          }
          break;
        case 404:
          text = '頁面不存在';
          break;
        case 500:
          text = '程式發生問題';
          break;
        default:
          text = errorMessage;
      }
      if (text !== '') {
        dialogStore.showDialog({
          title: '提示',
          text,
        });
      }
    }
    return Promise.reject(new Error(text || message));
  });

  return baseReq;
}

/**
 * 默認WebSocket setting
 * @param url - 連線URL
 * @param onMessage - 當返回 message = 'close'時執行的程序
 * @returns
 */
export function setWebSocket(url: string, onMessage?: (data: any) => void) {
  const baseURL = API_PATH.replace(/http/, 'ws');
  const hasSlash = /\/$/.test(baseURL) || /^\//.test(url);
  const ws: WebSocket = new WebSocket(`${baseURL}${!hasSlash ? '/' : ''}${url}`);
  ws.onerror = (error: any) => {
    throw new Error(error?.message);
  };
  if (onMessage) {
    ws.onmessage = async (e) => {
      const { message, data } = JSON.parse(e.data);
      switch (message) {
        case 'success':
        case 'logout':
          await onMessage(data);
          break;
        case 'close':
          ws.close();
          break;
        default:
          await onMessage(JSON.parse(e.data));
      }
    };
  }
  return ws;
}
